import { useOktaAuth } from "@okta/okta-react"
import { useInstancePermissions } from "../hooks/useInstancePermissions";
import { useParams } from "react-router-dom";
import { getInstancesByProducts } from "../utils/instance-helpers";
import { Grid, GridCell, GridContainer, Typography } from "@optimizely/axiom";
import { ProductCard } from "../components/ProductCard/ProductCard";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { useDecision } from "@optimizely/react-sdk";
import { FEATURE_FLAGS } from "../utils/constants";

export const DashboardPage = () => {
    const [decision] = useDecision(FEATURE_FLAGS.NEW_UI);
    const { enabled: showNewUI } = decision;

    const { organizationId } = useParams<{ organizationId: string }>();
    const { authState } = useOktaAuth();
    const { accessToken: token, idToken } = authState || {};
    const { accessToken = "" } = token || {};
    const { email= "", name } = idToken?.claims || {};
    const { instancePermissions, loading, error } = useInstancePermissions({ email, accessToken });

    if (loading) {
        return <LoadingIndicator />
    }

    if (error) {
        return <div>An error occurred: {error}</div>
    }
    const organizationInstances = instancePermissions?.filter((ip: any) => {
        return ip.OrganizationId === organizationId;
    });
    const instancesByProducts = getInstancesByProducts({ instances: organizationInstances });
    const message = organizationInstances?.length ? 'Dashboard shows all the products and instances you have access to. Select a product instance to get started.' : 'You have not been granted access to any entitlements in the org you navigated to. Please contact your account manager for assistance.';
    const organizationName = organizationInstances?.length ? organizationInstances[0].OrganizationName : "";


    return (
        <GridContainer>
            <Grid className="flex flex-column">
                {showNewUI && <Typography type="header1">New UI</Typography>}
                <Typography type="header1">
                    Welcome Back {name}!
                </Typography>
                <Typography type="body">
                    {message}
                </Typography>
                <Typography type="header4" className=" push--bottom">
                    {organizationName}
                </Typography>
            </Grid>
            <Grid>
                {Object.keys(instancesByProducts || {}).map(product => {
                    const productInstances = instancesByProducts[product] || [];

                    return (
                        <GridCell xlarge={4} large={4} medium={4} small={4} key={product}>
                            <ProductCard email={email} product={product} instances={productInstances} />
                        </GridCell>
                    )
                })}
            </Grid>
        </GridContainer>
    )
}

DashboardPage.displayName = 'DashboardPage';